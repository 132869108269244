<div class="wrapper">
<!-- Header -->
<np-header>
    <!-- Search -->
    <np-search [searchType]="SearchType.Global"></np-search>
    
    <h4 class="float-right welcome flex-center d-none d-md-flex" *ngIf="welcomeName"><a [routerLink]="['/search/MyLinks']">Welcome, {{ welcomeName }}</a></h4>
    <!-- Global (top menu) Navigation -->
    <np-menu [location]="NavigationLocation.Global"></np-menu>
  </np-header>
  
  <!-- Site-Specific Navigation -->
  <np-menu [location]="NavigationLocation.SiteSpecific"></np-menu>
  
  <!-- Main Content -->
  <main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
      <router-outlet></router-outlet>
    </section>
  </main>
  
  <!-- Footer -->
  <np-footer
    [opcoName]="'IT Foundation'"
    [opcoUrl]="'https://chevron.sharepoint.com/sites/ITFP'"
    [contentContactName]="'Application & Platform Engineering Services'"
    [contentContactUrl]="'https://go.chevron.com/cloudsupport'"
    [technicalContactName]="'Application & Platform Engineering Services'"
    [technicalContactUrl]="'https://go.chevron.com/cloudsupport'">
  </np-footer>
</div>