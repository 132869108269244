import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOpenMsTeams]'
})
export class OpenMsTeamsDirective {
  @Input("appOpenMsTeams") email: string;

  @HostListener('click') onClick() {
    if (this.email) {
      window.open(`https://teams.microsoft.com/l/chat/0/0?users=${this.email}`, "_blank");
    }
  }
}
