<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
    <h5>add owners</h5>
    <div class="flex flex-col">
        <app-url-owners *ngIf="!submitted" [(urlOwners)]="urlOwners" (urlOwnersChange)="ownersChanged($event)" [isEdit]="true" [isSubmitting]="false"></app-url-owners>
        <app-url-owners *ngIf="submitted"  [urlOwners]="owners.value" [isEdit]="true" [isSubmitting]="true"></app-url-owners>
    </div>
    <div *ngIf="owners.errors?.['serverError']" class="alert alert-danger">
        {{ owners.errors?.['serverError'] }}
    </div>
    <div class="flex center">
        <button [disabled]="submitted" type="button" class="branded button secondary" (click)="onCancel()">cancel</button>
        <button [disabled]="submitted || !duplicateCheckCompleted || !hasOwners" type="submit" class="branded button">add owner(s)</button>
    </div>
</form>