<div>
    <div *ngIf="isEdit">
        <label class="label-group branded">search for owner to add</label>
        <input [disabled]="isSubmitting" class="branded text-truncate input" type="text" autocomplete="off"
            placeholder="" [(ngModel)]="newOwnerSearchTerm" (keyup)="onSearchTermChanged($event)"
            (keydown.enter)="$event.preventDefault()">
        <span *ngIf="filteredOwners?.length > 1">
            <select npSelect [dropdownValues]="filteredOwnerIds" (change)="onOwnerSelected($event)"
                name="newOwnerNpSelect" id="newOwnerNpSelect">
                <option *ngFor="let user of filteredOwners;index as i " [value]="user.id">
                    {{user.displayName}}
                </option>
            </select>
        </span>
    </div>
    <div class="container" *ngIf="searching">
        <mat-spinner diameter=50></mat-spinner>
    </div>
    <div class="container">
        <table npTable>
            <caption>
                {{ ownersTableCaption }}
            </caption>
            <thead>
                <tr>
                    <th class="{{ isEdit ? '' : 'text-center' }}">owner name</th>
                    <th
                    *ngIf="isEdit"
                    class="action"
                    >action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let owner of urlOwners;">
                    <td class="{{ isEdit ? '' : 'text-center' }}">
                        <a href="javascript: void(0)" [appOpenMsTeams]="owner.ownerEmail">
                            {{ owner.ownerName }}
                        </a>
                    </td>
                    <td
                    *ngIf="isEdit"
                    class="action"
                    >
                        <span class="actionButton">
                            <button [disabled]="isSubmitting" class="button small secondary icon-expand-minus"
                                (click)="raiseRemoveOwnerRequest(owner.ownerObjectid)"
                            >
                            &nbsp;remove
                            </button>
                        </span>
                        
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
