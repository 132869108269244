import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationLocation, NextPageService, PageLayout, SearchType } from '@cvx/nextpage';
import { Subscription } from 'rxjs';
import { CalService } from '../../cal/cal.service';
import { NavigationService } from '../../services/navigation.service';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-branded-layout',
  templateUrl: './branded-layout.component.html',
  styleUrls: ['./branded-layout.component.scss']
})
export class BrandedLayoutComponent implements OnInit {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  SearchType = SearchType;
  welcomeName = '';
  title = 'Go';
  private getMyLinksCountSubscription: Subscription;

  constructor(
    private NP: NextPageService,
    private cal: CalService,
    private router: Router,
    private navigationService: NavigationService,
    private searchService: SearchService) {

    this.NP.options.sitename = 'go';

    this.cal.isUserSignedIn().then((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        this.welcomeName = this.cal.cvxClaimsPrincipal.name.split(',')[1].split('[')[0];
      }
      else {
        console.log("user not yet signed in");
      }
    });

    this.searchService.getMyLinksCount()
    .then(count => this.navigationService.updateMyLinksTitle(count))
    .catch(error => console.log(error));
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnDestroy(): void {
    this.getMyLinksCountSubscription?.unsubscribe();
  }
}
