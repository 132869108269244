<div class="row" *ngIf="urlMaps?.values">
    <div class="col">
        <div class="row flex center flex-col">
            <div>
                <form class="search p-3">
                    <input class="search-filter" type="hidden">
                    <input class="search-text" type="text" placeholder="search" aria-label="search"
                        [(ngModel)]="searchText" (change)="onChange()" [ngModelOptions]="{standalone: true}">
                    <button class="search-button" type="submit" (click)="onChange()" title="search"
                        aria-label="search"><em class="icon-search"></em></button>
                </form>
            </div>

            <div class="top-panel" *ngIf="urlMaps?.values.length > 0 && canAddOwners">
                <div class="rows-selected-container">
                    <div [hidden]="!selectedItems.size">
                        <span id="rowsSelectedLabel">{{selectedItems.size}} row{{selectedItems.size != 1 ? "s" : ""}} selected</span>
                    </div>
                </div>
                <div class="button-panel pt-3">
                    <div class="px-3">
                        <button class="button icon" (click)="addOwners()" [disabled]="!selectedItems.size" [matTooltip]="'add owners'" >
                            <fa-icon [icon]="faUserPlus" title="add owners"></fa-icon>
                        </button>
                    </div>
                    <div class="px-3">
                        <button class="button icon icon-trash-can" (click)="deleteLinks()" [disabled]="!selectedItems.size" [matTooltip]="'delete links'"></button>
                    </div>
                </div>
            </div>

            <div *ngIf="isLoading" class="pt-3">
                <mat-spinner></mat-spinner>
            </div>
            <div *ngIf="!isLoading" id="linksTable">
                <table npTable matSort (matSortChange)="sortData($event)">
                    <caption>search links</caption>
                    <thead>
                        <tr>
                            <th scope="col" *ngIf="urlMaps?.values.length > 0 && canAddOwners">
                                <div class="flex center">
                                    <input #selectAllCheckbox class="branded my-auto" type="checkbox"
                                        (change)="onChangeSelectAllCheckbox($event)" />
                                </div>
                            </th>
                            <th scope="col">Copy Link</th>
                            <th scope="col">Edit Link</th>
                            <th scope="col" mat-sort-header="shortName">Link Name</th>
                            <th scope="col" mat-sort-header="destinationUrl">Destination Link</th>
                            <th scope="col" mat-sort-header="ownerName" >Owner</th>
                            <th scope="col" mat-sort-header="creationDate">Created</th>
                            <th scope="col" mat-sort-header="modifiedDate">Modified</th>
                            <th scope="col" mat-sort-header="lastHitDate">Last Hit</th>
                            <th class="hit-counts" scope="col" mat-sort-header="hitCounts">Hits</th>
                            <th scope="col">QR Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of urlMaps.values">
                            <td *ngIf="canAddOwners">
                                <div class="flex center">
                                    <input #itemCheckbox type="checkbox" (change)="onChangeItemCheckbox($event, item.shortName)" class="branded my-auto">
                                </div>
                            </td>
                            <td>
                                <a (click)="copyLinkToClipboard(item.shortName)">
                                    <button id="qrCodeDownload" class="button icon icon-after-copy"></button>
                                </a>
                            </td>
                            <td>
                                <a routerLink="/details/{{ item.shortName }}">
                                    <button id="qrCodeDownload" class="button icon icon-pencil"></button>
                                </a>
                            </td>
                            <td class="selected">
                                <mat-icon *ngIf="item.healthStatus === UrlHealthStatus.Inactive && showHealth && !item['shortNameClicked']" matTooltip="inactive" class="small-icon">auto_delete</mat-icon>
                                <a [href]="getLink(item.shortName)" target="_blank" rel="noopener" (click)="onShortNameClick(item)" >{{ item.shortName }}</a>
                            </td>
                            <td><a rel="noopener" [href]="item.destinationUrl" target="_blank">{{ item.destinationUrl }}</a></td>
                            <td><a href="javascript: void(0)"
                                    [appOpenMsTeams]="item.owners[0].ownerEmail">{{ item.owners[0].ownerName }}</a></td>
                            <td>{{ item.creationDate | localDate }}</td>
                            <td>{{ item.modifiedDate ? (item.modifiedDate | localDate) : "Never" }}</td>
                            <td>{{ item.lastHitDate ? (item.lastHitDate | localDate) : "Never" }}</td>
                            <td>{{ item.hitCounts || 0 | number }}</td>
                            <td>
                                <a *ngIf="item.qrCodeImage" [href]="item.qrCodeImage" [download]="item.shortName">
                                    <button id="qrCodeDownload" class="button icon icon-after-download"></button>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="!isLoading && urlMaps?.values.length == 0">
                    <p class="flex center">No data was found</p>
                </div>
                <div class="flex center">
                    <a href="javascript: void(0)" (click)="onChange(urlMaps.metadata.previousPage)"
                        [class.disabled]="urlMaps.metadata?.currentPage <= 1">
                        <span class="icon-after-chevron-left flex center"></span>
                    </a>

                    <span class="m-2">{{ urlMaps.metadata?.currentPage || 0 }} of {{urlMaps.metadata?.totalPages || 0
                        }}</span>

                    <a href="javascript: void(0)" (click)="onChange(urlMaps.metadata.nextPage)"
                        [class.disabled]="urlMaps.metadata?.currentPage >= urlMaps.metadata?.totalPages">
                        <span class="icon-after-chevron-right flex center"></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>