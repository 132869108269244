import { Injectable } from "@angular/core";
import { StorageItem as StorageItem } from "../abstractions/storage-item";

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    setItem<T>(key: string, item: T): void {
        localStorage.setItem(this.getDomainKey(key), JSON.stringify(item));
    }

    getItem<T>(key: string): T {
        let data: any = localStorage.getItem(this.getDomainKey(key));
        if (!data) return null;

        let obj: T;

        try {
            obj = <T>JSON.parse(data);
        } catch (error) {
            obj = null;
        }

        return obj
    }

    setExpireItem(key: string, value: any, expiredAfterMins: number = 1) {
        let now = new Date();
        now.setMinutes(now.getMinutes() + expiredAfterMins);

        let item = new StorageItem();
        item.expiresOn = now.getTime();
        item.value = value;

        this.setItem(key, item);
    }

    getExpireItem(key: string) {
        let cacheItem = this.getItem<StorageItem>(key);

        if (cacheItem != null && new Date(cacheItem.expiresOn) > new Date()) {
            return cacheItem;
        }

        return null;
    }

    private getDomainKey(key: string): string {
        return `${document.location.origin}-${key}`;
    }
}