import { Injectable } from "@angular/core";
import { NextPageService } from "@cvx/nextpage";

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private pattern = /(\d+)/;

    constructor(
        private NP: NextPageService) {
    }

    public updateMyLinksTitle(myLinksCount: number) {
        for (let i in this.NP.options.navigationRoutes) {
            let route = this.NP.options.navigationRoutes[i];

            if (route.title != null && route.path?.includes("search/MyLinks")) {
                if (this.pattern.test(route.title)) {
                    route.title = route.title.replace(this.pattern, `${myLinksCount}`);
                }
                else if (myLinksCount > 0) {
                    route.title = `${route.title} (${myLinksCount})`;
                }
            }
        }
    }
}