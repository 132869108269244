import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UrlMap } from 'src/app/shared/abstractions/url-map';
import { GoHttpClient } from 'src/app/shared/services/go-http.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  destinationUrl = null;

  constructor(
    private router: Router,
    private goHttpClient: GoHttpClient) { }

  ngOnInit(): void {
    let goRedirectUrl = this.router.url.toLowerCase();
    const iePrefix = '/ie/';
    const edgePrefix = '/edge/'
    if (goRedirectUrl.startsWith(iePrefix)) {
      goRedirectUrl = goRedirectUrl.slice(iePrefix.length);
    }
    else if (goRedirectUrl.startsWith(edgePrefix)) {
      goRedirectUrl = goRedirectUrl.slice(edgePrefix.length);
    }
    else {
      goRedirectUrl = goRedirectUrl.slice(1); // removes leading slash
    }

    const documentReferrer = document.referrer;
    this.goHttpClient.Get<UrlMap>("api/redirect", { shortName: goRedirectUrl, referrerUrl: documentReferrer }, false)
      .then(response => {
        this.destinationUrl = response.destinationUrl;
        window.location.href = this.destinationUrl;
      })
      .catch(() => {
        this.router.navigate(["home"]);
      });
  }
}