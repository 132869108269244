import { Component, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ICvxClaimsPrincipal } from '@cvx/cal';
import { CalService } from 'src/app/shared/cal/cal.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { UrlMap } from '../../shared/abstractions/url-map';
import { GoHttpClient } from '../../shared/services/go-http.service';
import { Location } from '@angular/common'

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DetailsComponent implements OnInit {
  public shortName: string;
  public goUrl: string;
  public urlMap: UrlMap;
  public isLoading: boolean = true;
  public isEdit: boolean = false;
  public canEdit: boolean = false;
  public deleting: boolean = false;
  currentUser : ICvxClaimsPrincipal;
  private mode: string = "view";
  public isAdmin: boolean = false;
  @Output() urlId: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private goHttpClient: GoHttpClient,
    private authService: CalService,
    private location: Location,
    public dialog: MatDialog,
    ) {
  }

  ngOnInit(): void {
    const validModes : string[]=["view","edit"]

    this.shortName = this.route.snapshot.params["shortName"];
    this.mode = `${this.route.snapshot.params["mode"]}`.toLocaleLowerCase();
    
    if (!this.shortName) {
      this.router.navigate(["home"]);
    }

    if(! validModes.includes(this.mode))
    {
      this.mode = 'view';
    }

    this.currentUser = this.authService.cvxClaimsPrincipal;

    this.goHttpClient.Get<UrlMap>("api/url", { shortName: this.shortName })
      .then(response => {
        this.urlMap = response;
        this.goUrl = `${document.location.origin}/${response.shortName}`;
        this.shortName = response.shortName;
        this.urlId = response.id;

        this.canEdit = this.urlMap.owners.some(o=> o.ownerObjectid === this.currentUser.objectId);
        if (this.currentUser.roles?.includes("Go.Admin")) {
          this.isAdmin = true;
          this.canEdit = true;
        }

        if (this.mode == "edit" && ! this.canEdit)
        {
            //Edit but not owner
          this.router.navigate(['/details',`${this.shortName}`])
          this.mode="view"
        }

        this.isEdit = this.mode == "edit";

      })
      .catch(() => this.router.navigate(["home"]))
      .finally(() => this.isLoading = false);
  }

  public copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text);
  }

  public delete() : void
  {
    if(this.canEdit)
    {
      this.deleting=true;
      this.goHttpClient.Delete("api/Url", {"shortName": this.shortName})
        .then(() => {
          if (window.history.state.navigationId > 1) {  
            this.location.back()
          }
          else {
            this.router.navigate(['/']);
          }
        })
        .catch(ex => {
          console.log(ex)
          this.deleting=false;
        });
    }
  }

  confirmDialog(): void {
    const message = `Are you sure you want to delete Go link "` + this.shortName + `"?`;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "320px",
      autoFocus: false,
      data: {
        title: 'confirm delete',
        message: message
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.delete();
      }    
    });
  }

}
