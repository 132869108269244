import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NextPageRoute, NextPageService } from '@cvx/nextpage';
import { CalGuard } from './shared/cal/cal-guard';
import { DetailsComponent } from './pages/details/details.component';
import { HomeComponent } from './pages/home/home.component';
import { SearchComponent } from './pages/search/search.component';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { BrandedLayoutComponent } from './shared/components/branded-layout/branded-layout.component';

const routes: Routes = [
  {
    path: '',
    component: BrandedLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [CalGuard],
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [CalGuard]
      },
      {
        path: 'search/MyLinks',
        component: SearchComponent,
        canActivate: [CalGuard]
      },
      {
        path: 'search',
        component: SearchComponent,
        canActivate: [CalGuard]
      },
      {
        path: 'details/:shortName/:mode',
        component: DetailsComponent,
        canActivate: [CalGuard]
      },
      {
        path: 'details/:shortName',
        component: DetailsComponent,
        canActivate: [CalGuard]
      },
      {
        path: 'users/me',
        component: HomeComponent,
        canActivate: [CalGuard]
      },
    ]
  },
  {
    path: '**',
    component: RedirectComponent
  }
];

const navStructureRoutes: NextPageRoute[] = [
  {
    path: 'home',
    title: "home",
    component: HomeComponent,
  },
  {
    path: 'search/MyLinks',
    title: "my links",
    component: SearchComponent,
  },
  {
    path: 'search',
    title: "search links",
    component: SearchComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  constructor(private NP: NextPageService) {
    this.NP.options.navigationRoutes = navStructureRoutes;
  }
}
