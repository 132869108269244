import { Injectable } from "@angular/core";
import { GoHttpClient } from "./go-http.service";
import { StorageService } from "./storage-service";

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    public static MyLinksCountKey: string = "MyLinksCount";

    constructor(
        private goHttpClient: GoHttpClient,
        private storageService: StorageService) {
    }

    public getMyLinksCount(): Promise<number> {
        let cachedItem = this.storageService.getExpireItem(SearchService.MyLinksCountKey);

        if (cachedItem != null) {
            return Promise.resolve(cachedItem.value);
        }

        return this.goHttpClient.Get<number>("api/Search/MyLinks/Count").then(count => {
            this.storageService.setExpireItem(SearchService.MyLinksCountKey, count);
            return count;
        });
    }
}