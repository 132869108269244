import { Component, Input, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { Activity } from 'src/app/shared/Interfaces/activities';
import { GoHttpClient } from 'src/app/shared/services/go-http.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-redirect-activities',
  templateUrl: './redirect-activities.component.html',
  styleUrls: ['./redirect-activities.component.scss']
})
export class RedirectActivitiesComponent implements OnInit {
  @Input() public shortName: string;
  @Input() isMonthCountControlVisible: boolean = true;

  public monthCount: number = 6;
  public dailyActivity: Chart;
  public isLoading = true;
  public timeRanges: number[] = [12, 6, 3];
  public noData: boolean;

  constructor(private goHttpClient: GoHttpClient, private datePipe: DatePipe) { }

  

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    if (this.dailyActivity) {
      this.dailyActivity.stop();
      this.dailyActivity.destroy();
    }
  }

  public onTimeRangeChanged() {
    this.loadData();
  }

  public getTimeRangeLabel(timeRange: number): string {
    let isPlural = timeRange > 1;
    return `${timeRange} month${isPlural ? "s" : ""}`;
  }

  private loadData() {
    this.isLoading = true;

    this.goHttpClient.Get<Activity[]>("api/redirect/activity", { shortName: this.shortName, monthCount: this.monthCount })
      .then(data => {
        if (data && data.length > 0) {

          this.dailyActivity = this.dailyActivity || new Chart('dailyActivityCanvas', {
            type: 'line',
            data: null,
            options: {
              responsive: true,
              maintainAspectRatio: false,
               scales: {
                      y: {
                        min: 0
                      }
                    }
            },
          });
          this.dailyActivity.data = {
            labels: data.map(l => this.datePipe.transform(l.activityDate, 'MMM, y')),
            datasets: [{
              label: "activity",
              data: data.map(s => s.activityCount),
              borderColor: '#0066B2',
              backgroundColor: 'rgba(255,255,255, 0.3)'
            }]
          };
          this.dailyActivity.update();
          this.noData = false;
        } else {
          this.noData = true;
        }
      })
      .catch(error => console.log(error))
      .finally(() =>{
        this.isLoading = false;
      });
  }
}
