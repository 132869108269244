<div class="container" *ngIf="isloading || duplicateLinks?.length > 0">
    <div *ngIf="!isloading && duplicateLinks?.length > 0" class="alert alert-danger">
        <p>
            The following GO {{ duplicateLinks.length > 1 ? "links have" : "link has" }} the same destination.
            <br />
            Would {{ duplicateLinks.length > 1 ? "one of them" : "it" }} work?
        </p>
        <table npTable>
            <caption>duplicate links</caption>
            <thead>
                <tr>
                    <th>Link Name</th>
                    <th>Hits</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of duplicateLinks">
                    <td>
                        <a href="/details/{{ item.shortName }}" target="_blank">{{ item.shortName }}</a>
                    </td>
                    <td>
                        {{ item.hitCounts || 0 | number }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="isloading">
        <mat-spinner diameter=50></mat-spinner>
    </div>
</div>