<div class="row">
    <div class="col home-container">
        <div class="row flex center">
            <h2>sharing links has never been easier</h2>
        </div>
        <div class="row home">
            <div class="col-md-12 flex-center">
                <app-create-url-form></app-create-url-form>
            </div>
        </div>
    </div>
</div>
