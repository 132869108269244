import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CalService } from "./cal.service";

@Injectable()
export class RoleGuard implements CanActivate {

    constructor(private authService: CalService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.authService.isInGroup(next.data["roles"]);
    }
}