import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { UrlMap } from 'src/app/shared/abstractions/url-map';
import { GoHttpClient } from 'src/app/shared/services/go-http.service';
import { GoFormGroup } from 'src/shared/go-form-group';
import { GoValidators } from '../../validators/go-validators';
import { UrlOwner } from '../../abstractions/url-owner';
import { UrlRegex } from '../../regex/url.regex';

@Component({
  selector: 'app-update-url-form',
  templateUrl: './update-url-form.component.html',
  styleUrls: ['./update-url-form.component.scss']
})
export class UpdateUrlFormComponent implements OnInit {
  @Input() urlMap: null | UrlMap;
  @Input() public deleting: boolean = false;

  public form: GoFormGroup;
  public submitted: boolean = false;
  public searching: boolean = false;
  public hasOwners: boolean = false;

  readonly searchMaxUserResults: number = 10;
  minSearchTermLength: number = 2;
  searchDebounceSeconds: number = 1;
  duplicateCheckCompleted: boolean;
  public readonly destinationUrlErrorMessage = UrlRegex.httpHttpsUrlPatternErrorMessage

  constructor(private goHttpClient: GoHttpClient, private router: Router) {
  }

  ngOnInit(): void {
    this.form = new GoFormGroup({
      url: new FormControl(this.urlMap.destinationUrl || '', Validators.compose([
        Validators.required,
        Validators.pattern(UrlRegex.httpHttpsUrlPattern),
        GoValidators.isNotGoDomain(),
      
      ])),
      owners: new FormControl(this.urlMap.owners)
    });

    this.hasOwners = this.urlMap.owners != null && this.urlMap.owners.length > 0;
  }

  get url() { return this.form.get('url'); }  
  get owners() { return this.form.get('owners'); }

  public onSubmit(data) {
    if (this.form.valid) {
      this.urlMap.destinationUrl = data.url
      this.submitted = true;
      this.goHttpClient.Patch("api/Url", this.urlMap)
        .then(() => {
          this.router.navigate([`details/${this.urlMap.shortName}`]);
        })
        .catch(ex => this.form.updateValidationSummary(ex))
        .finally(() => this.submitted = false);
    }
  }

  public checkForDuplicate(completed: boolean) {
    this.duplicateCheckCompleted = completed;
  }

  ownersChanged(newOwners: UrlOwner[])
  {
    this.urlMap.owners = newOwners
    this.owners.setValue(this.urlMap.owners)
    this.hasOwners = this.urlMap.owners != null && this.urlMap.owners.length > 0;
  }
}
