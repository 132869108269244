import { HttpClient } from '@angular/common/http';
import { IAppConfig } from '../abstractions/app-config';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
  })
export class AppConfig {
    static settings: IAppConfig;
    constructor(private http: HttpClient) {}
    load() {
        const appConfFile = `assets/config.json`;
        return new Promise<void>((resolve, reject) => {
            // App Config
            this.http.get(appConfFile).toPromise().then((response: IAppConfig) => {
                AppConfig.settings = response;
                resolve();
            }).catch((response: any) => {
                reject(`Could not load file '${appConfFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}
