import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { UrlOwner } from '../../abstractions/url-owner';
import { GraphUserProps, GraphUserResponse, UserHttpClient } from '../../services/user-http.service';

@Component({
  selector: 'app-url-owners',
  templateUrl: './url-owners.component.html',
  styleUrls: ['./url-owners.component.scss']
})
export class UrlOwnersComponent implements OnInit, OnChanges {

  @Input() isSubmitting: boolean;
  @Input() isEdit: boolean;
  @Input() urlOwners: UrlOwner[];
  @Output() urlOwnersChange = new EventEmitter<UrlOwner[]>(); 

  public ownersTableCaption=""
  public newOwnerSearchTerm: string = "";
  searchTermChanged: Subject<string> = new Subject<string>();

  readonly pleaseSelectOption: GraphUserProps = { id: "0", displayName: "add owner...", mail: "choices@chevron.com" }
  filteredOwners: GraphUserProps[] = [];
  filteredOwnerIds: string[] = [];

  public searching: boolean = false;

  readonly searchMaxUserResults: number = 10;
  minSearchTermLength: number = 2;
  searchDebounceSeconds: number = 1;

  constructor(private userHttpClient: UserHttpClient) { }

  ngOnInit(): void {
    this.autoSearchOwner()
  }
  //autocomplete
  autoSearchOwner() {
    this.searchTermChanged
      .pipe(
        filter(res => {
          return res !== null &&
            res.length >= this.minSearchTermLength 
        }),
        distinctUntilChanged(),
        debounceTime(1000 * this.searchDebounceSeconds),
        tap(() => {
          this.UpdateFilteredOwners([]);
          this.searching = true;
        }),
        switchMap(value =>
          this.userHttpClient.searchForUser(value, this.searchMaxUserResults)
            .pipe(
              finalize(() => {
                this.searching = false
              })
            )
        )
      )
      .subscribe((res: GraphUserResponse) => {
        if (res.value.length > 1) {
          //setup picking
          this.UpdateFilteredOwners(res.value);
        }
        else if (res.value.length == 1) {
          //select it and display
          this.addOwner(res.value[0]);
          this.clearOwnerValue();
        }

      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.urlOwners = changes['urlOwners']?.currentValue;
    this.isSubmitting = changes['isSubmitting']?.currentValue;

    if(changes['urlOwners'])
    {
      this.updateOwnersTableCaption();
    }
    
  }

  private updateOwnersTableCaption() 
  {
    if(this.urlOwners && this.urlOwners?.length !== 0)
    {
      this.ownersTableCaption = 'current owners' 
    }
    else if(this.isEdit)
    {
      this.ownersTableCaption = 'please add at least one owner'
    }
    else
    {
      this.ownersTableCaption = 'no owners found'
    }
  }
  public raiseAddOwnerRequest(ownerToAdd: UrlOwner)
  {
    if(ownerToAdd != null && !this.urlOwners.some(o=> o.ownerObjectid == ownerToAdd.ownerObjectid))
    {
      this.urlOwners.push(ownerToAdd)
      this.urlOwnersChange.emit(this.urlOwners)
      this.updateOwnersTableCaption();
    }
  }
  public raiseRemoveOwnerRequest(objectId: string)
  {
    const ownerToRemove = this.urlOwners.find(o=> o.ownerObjectid === objectId)
    
    if(ownerToRemove != undefined)
    {
      this.urlOwners = this.urlOwners.filter(o=> o.ownerObjectid != ownerToRemove.ownerObjectid)
      this.urlOwnersChange.emit(this.urlOwners)
      this.updateOwnersTableCaption();
    }
  }

  private addOwner(owner: GraphUserProps)
  {
    if(!this.urlOwners.some(o=> o.ownerObjectid === owner.id))
    {
      const newUrlOwner : UrlOwner = {
        ownerObjectid: owner.id,
        ownerEmail: owner.mail,
        ownerName: owner.displayName
      }
      this.raiseAddOwnerRequest(newUrlOwner);
    }
  }

  private UpdateFilteredOwners(newListUsers: GraphUserProps[]) {
    this.filteredOwners = newListUsers;
    if (this.filteredOwners?.length > 1) {
      this.filteredOwners.unshift(this.pleaseSelectOption)
      this.filteredOwnerIds = this.filteredOwners?.map(function (user: GraphUserProps) { return user.id })
    }
    else {
      this.filteredOwnerIds = [];
    }
  }
  public onSearchTermChanged(event: any)
  {
    const newSearchTerm: string = (event.target as HTMLInputElement)?.value;
    this.searchTermChanged.next(newSearchTerm)
  }

  public onOwnerSelected(event: Event) {
    const objectid = (<HTMLInputElement>event.target).value;
    if (objectid !== this.pleaseSelectOption.id) {
      const _graphUserProps = this.filteredOwners.filter(u => { return u.id === objectid; })
      if (_graphUserProps !== null && _graphUserProps.length == 1) {

        this.addOwner(_graphUserProps[0]);
        this.clearOwnerValue();
        this.UpdateFilteredOwners([]);
      }
    }
  }
  private clearOwnerValue() {
    this.newOwnerSearchTerm = null;
  }

}
