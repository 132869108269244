import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) { }

  transform(value: Date, format: string = "mediumDate"): string {
    const date = new Date(value);
    let localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

    return this.datePipe.transform(localDate, format);
  }
}
