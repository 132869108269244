import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from './app-config.service';
import { CalService } from '../cal/cal.service';
import { CalConfig } from '../cal/cal-config';
import { AuthenticationResult } from '@azure/msal-browser';
import { StorageService } from './storage-service';

@Injectable({
    providedIn: 'root',
})
export class GoHttpClient{
    private lastUrlCreationDeletion: number;
    private readonly secondsToDisableMyLinksCreationDeletion = 60;
    private tokenCacheKey: string = "token";
    private apiUrl: string = AppConfig.settings.apiUrl + '/';
    private httpHeaders: HttpHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': AppConfig.settings.apiUrl,
        'Access-Control-Allow-Methods': 'GET',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Max-Age': '86400'
    });

    constructor(
        private httpclient: HttpClient,
        private cal: CalService,
        private storageService: StorageService,) { }

    async Post<T>(endpoint: string, body: any = null): Promise<T> {
        return this.updateAuthorizationHeader(this.httpHeaders)
            .then(_headers => this.httpclient.post<T>(this.apiUrl + endpoint, body, {
                headers: _headers,
            }).toPromise()).finally(()=> this.setlastUrlCreationDeletion(endpoint))
    }

    async Patch<T>(endpoint: string, body: any = null, addBearerToken: boolean = true): Promise<T> {
        if (addBearerToken) { 
            return this.updateAuthorizationHeader(this.httpHeaders)
            .then(_headers => this.httpclient.patch<T>(this.apiUrl + endpoint, body, {
                headers: _headers,
            }).toPromise())
        }
        else {
            return this.httpclient.patch<T>(this.apiUrl + endpoint, body, { headers: this.httpHeaders }).toPromise();
        }
    }
    
    async Get<T>(endpoint: string, params: any = null, addBearerToken: boolean = true): Promise<T> 
    {
        let headers = this.addCacheControlNoneHeader(endpoint,params,this.httpHeaders)

        if (addBearerToken) {
            return this.updateAuthorizationHeader(headers)
            .then(_headers => this.httpclient.get<T>(this.apiUrl + endpoint, {
                headers: _headers,
                params: params
            }).toPromise());
        }
        else {
            return this.httpclient.get<T>(this.apiUrl + endpoint, { params: params, headers: headers}).toPromise();
        }
    }

    async Delete<T>(endpoint: string, params: any = null): Promise<object> {       
        return this.updateAuthorizationHeader(this.httpHeaders)
            .then(_headers => this.httpclient.delete(this.apiUrl + endpoint, {
                headers: _headers,
                params: params
            }).toPromise()).finally(()=> this.setlastUrlCreationDeletion(endpoint))
    }

    private addCacheControlNoneHeader(endpoint: string, params: any, headers: HttpHeaders): HttpHeaders
    {
        if(this.lastUrlCreationDeletion != undefined && 
            endpoint == "api/Search" && 
            "type" in params && 
            params["type"] == "MyLinks" && 
            (Date.now() - this.lastUrlCreationDeletion) < (this.secondsToDisableMyLinksCreationDeletion * 1000))
        {
            return headers.append("Cache-Control","no-cache")
        }
        else
        {
            return headers
        }
    }

    private setlastUrlCreationDeletion(endpoint: string)
    {
        if(endpoint == "api/Url")
        {
            this.lastUrlCreationDeletion = Date.now()
        }
    }

    private updateAuthorizationHeader(headers: HttpHeaders): Promise<HttpHeaders> {
        const graphScope = CalConfig.settings.graphScopes.slice(0); // clone array
        graphScope.unshift(`${AppConfig.settings.clientId}/user_impersonation`);

        let cachedToken = this.storageService.getExpireItem(this.tokenCacheKey);

        if (cachedToken != null && cachedToken.value != null) {
            return Promise.resolve(headers.append("Authorization", `Bearer ${cachedToken.value}`));
        }

        return this.cal.getAADToken(graphScope, true).then((response: AuthenticationResult) => {
            let tokenExpiresOn = new Date(0);
            tokenExpiresOn.setUTCSeconds(response.idTokenClaims["exp"]);
            let now = new Date();
            let diff = new Date(tokenExpiresOn.getTime() - now.getTime()).getMinutes();

            this.storageService.setExpireItem(this.tokenCacheKey, response.idToken, diff);

            return headers.append("Authorization", `Bearer ${response.idToken}`);
        })
    }
}
