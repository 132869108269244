<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
    <div class="flex flex-col">
        <label class="label-group branded">destination url</label>
        <input [disabled]="submitted" class="branded text-truncate input" formControlName="url" type="text" autocomplete="off"
            placeholder="https://">
        <app-duplicate-links [urlToCheck]="url.value" (completedDuplicateCheck)="checkForDuplicate($event)"></app-duplicate-links>
        <div *ngIf="url.invalid && (url.dirty || url.touched || submitted)" class="alert alert-danger">
            <div *ngIf="url.errors?.['required']">
                Destination URL is required.
            </div>
            <div *ngIf="url.errors?.['pattern']">
                {{ destinationUrlErrorMessage }}
            </div>
            <div *ngIf="url.errors?.['isGoDomain']">
                go domain is not allowed
            </div>
            <div *ngIf="url.errors?.['serverError']">
                {{ url.errors?.['serverError'] }}
            </div>
        </div>
    </div>
    <div class="flex flex-col">
        <app-url-owners *ngIf="!submitted" [(urlOwners)]="urlMap.owners" (urlOwnersChange)="ownersChanged($event)" [isEdit]="true" [isSubmitting]="false"></app-url-owners>
        <app-url-owners *ngIf="submitted" [urlOwners]="owners.value" [isEdit]="true" [isSubmitting]="true"></app-url-owners>
    </div>
    <div class="flex center">
        <button [disabled]="submitted || deleting || !hasOwners || !duplicateCheckCompleted" type="submit" class="branded button">save
            changes</button>
    </div>
</form>