import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ICvxClaimsPrincipal } from '@cvx/cal';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UrlMap } from 'src/app/shared/abstractions/url-map';
import { GoHttpClient } from 'src/app/shared/services/go-http.service';
import { GoFormGroup } from 'src/shared/go-form-group';
import { UrlOwner } from '../../abstractions/url-owner';
import { UrlRegex } from '../../regex/url.regex';

@Component({
  selector: 'add-owners-form',
  templateUrl: './add-owners-form.component.html',
  styleUrls: ['./add-owners-form.component.scss']
})
export class AddOwnersFormComponent implements OnInit {
  public form: GoFormGroup;
  public urlOwners: UrlOwner[] = [];
  public hasOwners: boolean = false;
  public submitted: boolean = false;
  public urlMap: UrlMap;
  public duplicateCheckCompleted = true;
  public readonly destinationUrlErrorMessage = UrlRegex.httpHttpsUrlPatternErrorMessage
  currentUser : ICvxClaimsPrincipal;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: {shortNames: string[]},
    private dialogRef: MatDialogRef<AddOwnersFormComponent, boolean>,
    private goHttpClient: GoHttpClient) {
  }

  ngOnInit(): void {
    this.form = new GoFormGroup({
      shortnames: new FormControl(this.dialogData.shortNames),
      owners: new FormControl(this.urlOwners, Validators.compose([Validators.required]))
    });
  }

  ownersChanged(newOwners: UrlOwner[])
  {
    this.urlOwners = newOwners;
    this.owners.setValue(this.urlOwners)
    this.hasOwners = this.urlOwners != null && this.urlOwners.length > 0;
  }

  get owners() { return this.form.get('owners'); }

  public async onSubmit(data) {
    this.submitted = true;

    this.goHttpClient.Post("api/Url/owners", data)
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch(ex => this.form.updateValidationSummary(ex))
      .finally(() => this.submitted = false);
  }

  public onCancel() {
    this.dialogRef.close(false);
  }
}
