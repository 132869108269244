import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { UrlRegex } from "../regex/url.regex";
import { GoHttpClient } from "../services/go-http.service";



export class GoValidators extends Validators {

    private static reservedShortNames: string[];
    private static goDomainPattern = new RegExp(UrlRegex.notGoUrlPattern);

    public static reservedShortNamesValidator(goHttpClient: GoHttpClient): ValidatorFn {
        if (!GoValidators.reservedShortNames) {
            goHttpClient.Get<string[]>("api/Url/ReservedShortNames")
                .then(response => {
                    this.reservedShortNames = response;
                });
        }

        return (control: AbstractControl): ValidationErrors | null => {
            const forbidden = this.reservedShortNames?.includes(control.value);
            return forbidden ? { reserved: true } : null;
        };
    }

    public static isNotGoDomain(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }
            
            const forbidden = !this.goDomainPattern.test(control.value);
            return forbidden ? { isGoDomain: true } : null;
        };
    }

}