export class UrlRegex
{
    private static readonly goEnvironment: string = "(-(prod|test|dev)[0-9]{0,1})?"
    private static readonly azureChevronDomain: string = '([\.]azure)?[\.]chevron[\.]com'
    private static readonly allowedProtocal: string = '(http|https):[/]{2}'
    private static readonly domainSubdomain: string = "[A-Za-z0-9]{1,64}(([-.]{1}[A-Za-z0-9]{1,64}){0,12})?"
    private static readonly urlPortNumber: string = "(:[0-9]{0,6})?"
    private static readonly urlPath: string = "(\/[a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_=():@!]{0,1024})?"
    public static readonly httpHttpsUrlPattern: string = "^" + UrlRegex.allowedProtocal + UrlRegex.domainSubdomain + UrlRegex.urlPortNumber + UrlRegex.urlPath +"$";
    public static readonly httpHttpsUrlPatternErrorMessage = "The Destination URL is not a valid fully-qualified http or https URL."
    public static readonly notGoUrlPattern = "^((?!"+ UrlRegex.allowedProtocal + "go" + UrlRegex.goEnvironment + UrlRegex.azureChevronDomain + ").*)$";
}