<div class="row" *ngIf="referrerList?.values">
    <div class="col">
        <h2 class="mt-3">referrers</h2>
        <table npTable>
            <caption>
                {{
                    referrerList?.values.length !== 0 && referrerList !== null ?
                    'urls that sent visitors to this go link'
                    :
                    'no data was found'
                }}
            </caption>
            <thead>
                <tr>
                    <th scope="col">Referrer URL</th>
                    <th scope="col">Referrer Counts</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of referrerList.values">
                    <td class="selected"><a href="{{ item.referrerUrl }}">{{ item.referrerUrl }}</a></td>
                    <td>{{ item.activityCount }}</td>
                </tr>
            </tbody>
        </table>
        <div class="flex center">
            <a href="javascript: void(0)" (click)="loadPage(referrerList.metadata.previousPage)"
                [class.disabled]="referrerList.metadata?.currentPage <= 1">
                <span class="icon-after-chevron-left flex center"></span>
            </a>

            <p class="m-2">{{ referrerList.metadata?.currentPage || 0 }} of {{referrerList.metadata?.totalPages || 0
                }}</p>

            <a href="javascript: void(0)" (click)="loadPage(referrerList.metadata.nextPage)"
                [class.disabled]="referrerList.metadata?.currentPage >= referrerList.metadata?.totalPages">
                <span class="icon-after-chevron-right flex center"></span>
            </a>
        </div>
    </div>
</div>