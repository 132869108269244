<div class="row">
    <div class="col">
        <h2>redirect activities</h2>
        <mat-form-field class="expandable-select sm" appearance="outline" (click)="$event.stopPropagation()"
            *ngIf="isMonthCountControlVisible">
            <mat-label>Month range</mat-label>
            <mat-select [(value)]="monthCount" (selectionChange)="onTimeRangeChanged()">
                <mat-option *ngFor="let timeRange of timeRanges" [value]="timeRange">
                    {{getTimeRangeLabel(timeRange)}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="!noData; else noDataPanel">
            <div style="height: 500px;">
                <canvas id="dailyActivityCanvas" [hidden]="isLoading" class="chart chart-line">
                    {{ dailyActivity }}
                </canvas>
                <div class="d-flex justify-content-center align-items-center h-100">
                    <mat-spinner [hidden]="!isLoading"></mat-spinner>
                </div>
               
            </div>
        </div>
        <ng-template #noDataPanel>
            <div style="opacity: 0.2;">
                <h5>No data available</h5>
            </div>
        </ng-template>
    </div>
    <br /><br />
</div>