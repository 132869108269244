<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
    <div class="flex flex-col">
        <label class="label-group branded">short name</label>
        <input [disabled]="submitted" class="branded input" formControlName="shortname" type="text" autocomplete="off" placeholder="go">
        <div *ngIf="shortname.invalid && (shortname.dirty || shortname.touched || submitted)"
            class="alert alert-danger">
            <div *ngIf="shortname.errors?.['required']">
                Short Name is required.
            </div>
            <div *ngIf="shortname.errors?.['pattern']">
                Short Name must be between 2 and 50 alphanumeric characters including hypens and underscores.
                Spaces are not allowed.
            </div>
            <div *ngIf="shortname.errors?.['reserved']">
                Short Name is reserved
            </div>
            <div *ngIf="shortname.errors?.['serverError']">
                {{ shortname.errors?.['serverError'] }}
            </div>
        </div>
    </div>
    <div class="flex flex-col">
        <label class="label-group branded">destination url</label>
        <input [disabled]="submitted" class="branded text-truncate input" formControlName="url" type="text" autocomplete="off"
            placeholder="https://">
        <app-duplicate-links [urlToCheck]="url.value" (completedDuplicateCheck)="checkForDuplicate($event)"></app-duplicate-links>
        <div *ngIf="url.invalid && (url.dirty || url.touched || submitted)" class="alert alert-danger">
            <div *ngIf="url.errors?.['required']">
                Destination URL is required.
            </div>
            <div *ngIf="url.errors?.['pattern']">
                {{ destinationUrlErrorMessage }}
            </div>
            <div *ngIf="url.errors?.['isGoDomain']">
                go domain is not allowed
            </div>
            <div *ngIf="url.errors?.['serverError']">
                {{ url.errors?.['serverError'] }}
            </div>
        </div>
    </div>
    <div class="flex flex-col">
        <app-url-owners *ngIf="!submitted" [(urlOwners)]="urlOwners" (urlOwnersChange)="ownersChanged($event)" [isEdit]="true" [isSubmitting]="false"></app-url-owners>
        <app-url-owners *ngIf="submitted"  [urlOwners]="owners.value" [isEdit]="true" [isSubmitting]="true"></app-url-owners>
    </div>
    <div class="flex center">
        <button [disabled]="submitted || !duplicateCheckCompleted || !hasOwners" type="submit" class="branded button">create link</button>
    </div>
</form>