import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UrlMap } from '../../abstractions/url-map';
import { GoHttpClient } from '../../services/go-http.service';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-duplicate-links',
  templateUrl: './duplicate-links.component.html',
  styleUrls: ['./duplicate-links.component.scss']
})
export class DuplicateLinksComponent implements OnInit {

  @Input() urlToCheck: string;
  @Output() completedDuplicateCheck = new EventEmitter<boolean>();
  public duplicateLinks: UrlMap[];
  private minDupUrlTermLength = 9;
  private dupUrlDebounceSeconds = 1;
  public isloading = false;
  urlChanged: Subject<string> = new Subject<string>();

  constructor(private goHttpClient: GoHttpClient) { }

  ngOnInit(): void {
    this.urlChanged
      .pipe(
        filter(res => {
          return res?.length >= this.minDupUrlTermLength
        }),
        distinctUntilChanged(),
        debounceTime(1000 * this.dupUrlDebounceSeconds),
        tap(()=> {
          this.isloading=true;
          this.completedDuplicateCheck.emit(!this.isloading);
        })
        )
      .subscribe(newUrl => {
        this.checkForDuplicates(newUrl)
      })
    this.completedDuplicateCheck.emit(!this.isloading);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.duplicateLinks = [];
    const newUrl = changes['urlToCheck']?.currentValue;
    if (newUrl?.length > 0) {
      this.urlChanged.next(newUrl);
    }
  }

  checkForDuplicates(newUrl: string, maxDuplicatesToReturn: number = 3) {
    this.duplicateLinks = [];
    if (newUrl?.length > 0) {
      this.goHttpClient.Get<UrlMap[]>("api/Url/Duplicates", { url: newUrl, maxDuplicatesToReturn: maxDuplicatesToReturn }, true).then(response => {
        this.duplicateLinks = response;
      })
        .catch(error => console.log(`Error checking for duplicate: ${error}`))
        .finally(() => {
          this.isloading=false;
          this.completedDuplicateCheck.emit(!this.isloading)
        })
    }
  }
}