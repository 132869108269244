import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ICvxClaimsPrincipal } from '@cvx/cal';
import { UrlMap } from 'src/app/shared/abstractions/url-map';
import { GoHttpClient } from 'src/app/shared/services/go-http.service';
import { GoFormGroup } from 'src/shared/go-form-group';
import { UrlOwner } from '../../abstractions/url-owner';
import { CalService } from '../../cal/cal.service';
import { UrlRegex } from '../../regex/url.regex';
import { GoValidators } from '../../validators/go-validators';

@Component({
  selector: 'app-create-url-form',
  templateUrl: './create-url-form.component.html',
  styleUrls: ['./create-url-form.component.scss']
})
export class CreateUrlFormComponent implements OnInit {
  public form: GoFormGroup;
  public urlOwners: UrlOwner[] = [];
  public hasOwners: boolean = false;
  public submitted: boolean = false;
  public urlMap: UrlMap;
  public duplicateCheckCompleted = true;
  public readonly destinationUrlErrorMessage = UrlRegex.httpHttpsUrlPatternErrorMessage
  currentUser : ICvxClaimsPrincipal;

  constructor(
    private goHttpClient: GoHttpClient, 
    private router: Router,
    private authService: CalService) {
  }

  ngOnInit(): void {
    this.form = new GoFormGroup({
      shortname: new FormControl("", Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9_-]{2,50}$"),
        GoValidators.reservedShortNamesValidator(this.goHttpClient)
      ])),
      url: new FormControl("", Validators.compose([
        Validators.required,
        Validators.pattern(UrlRegex.httpHttpsUrlPattern),
        GoValidators.isNotGoDomain()
      ])),
      owners: new FormControl(this.urlOwners)
    });
    
    this.currentUser = this.authService.cvxClaimsPrincipal;
    
    const initOwner : UrlOwner = {
        ownerObjectid: this.currentUser.objectId,
        ownerEmail: this.currentUser.email,
        ownerName: this.currentUser.name
    }

    this.urlOwners.push(initOwner)
    this.owners.setValue(this.urlOwners)
    this.hasOwners=true;
  }

  ownersChanged(newOwners: UrlOwner[])
  {
    this.urlOwners = newOwners;
    this.owners.setValue(this.urlOwners)
    this.hasOwners = this.urlOwners != null && this.urlOwners.length > 0;
  }

  get shortname() { return this.form.get('shortname'); }
  get url() { return this.form.get('url'); }
  get owners() { return this.form.get('owners'); }

  public onSubmit(data) {
    if (this.form.valid) {
      this.submitted = true;
      this.goHttpClient.Post("api/Url", data)
        .then(() => {
          this.router.navigate([`details/${this.shortname.value}`]);
        })
        .catch(ex => this.form.updateValidationSummary(ex))
        .finally(() => this.submitted = false);
    }
  }

  public checkForDuplicate(completed: boolean)
  {
    this.duplicateCheckCompleted = completed;
  }
}
