import { Injectable } from '@angular/core';
import {
  AuthenticationResult
} from '@azure/msal-browser';

import { CalClient, ICalClient, Config, ICvxClaimsPrincipal } from '@cvx/cal';
import { CalConfig } from './cal-config';

@Injectable()
export class CalService implements ICalClient {
  private _config: Config;
  private _cal: CalClient;

  constructor() {
    this._config = CalConfig.settings;
    this._cal = new CalClient(this._config);
  }

  get cvxClaimsPrincipal(): ICvxClaimsPrincipal {
    return this._cal.cvxClaimsPrincipal;
  }

  async isUserSignedIn(): Promise<boolean> {
    return this._cal.isUserSignedIn();
  }

  async userInitiatedSignIn(): Promise<ICvxClaimsPrincipal> {
    return this._cal.userInitiatedSignIn();
  }

  async userInitiatedSignOut(): Promise<boolean> {
    return this._cal.userInitiatedSignOut();
  }

  async isInGroup(groupCheck: any): Promise<boolean> {
    return this._cal.isInGroup(groupCheck);
  }

  async getUserPropertiesFromMsGraph(properties: string[]): Promise<any> {
    return this._cal.getUserPropertiesFromMsGraph(properties);
  }

  async getAADToken(
    oidcScopes: string[] = this._config.oidcScopes,
    fullAuthResponse: boolean = false
  ): Promise<string | AuthenticationResult | null> {
    return this._cal.getAADToken(oidcScopes, fullAuthResponse);
  }

  getAccount() {
    return this._cal.getAccount();
  }

  getIdTokenClaims() {
    return this._cal.getIdTokenClaims();
  }

  async getClaims() {
    return this._cal.getClaims();
  }

  getAccessTokenFromCache() {
    return this._cal.getAccessTokenFromCache();
  }
}