import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { AppInsightsService } from "./app-insights.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private injector: Injector) {
        super();
    }

    override handleError(error: Error) {
        let appInsightsService: AppInsightsService = this.injector.get(AppInsightsService);

        // Manually log exception
        appInsightsService.logException(error);

        // write to console like default
        console.log('error', error)
    }
}