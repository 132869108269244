import { FormGroup } from "@angular/forms";

export class GoFormGroup extends FormGroup {
    private ForbiddenStatusCode: number = 403;
    private BadRequestStatusCode: number = 400;

    public updateValidationSummary(ex) {
        if (ex.status == this.BadRequestStatusCode || ex.status == this.ForbiddenStatusCode) {
            let detail = JSON.parse(ex.error.detail);

            if (detail) {
                let keys = Object.keys(detail);
                for (let index in keys) {
                    let key = keys[index];

                    const formControl = this.get(key.toLowerCase());

                    if (formControl) {
                        let message = detail[key] || "Internal Error";

                        formControl.setErrors({
                            serverError: message
                        });
                    }
                }
            }
            else {
                console.error("Unhandled error. Invalid server response.");
            }
        }
        else {
            console.error(`Unhandled error. Details: ${ex.error}`);
        }
    }
}