import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { CalService } from "./cal.service";
import { UrlString, BrowserUtils } from '@azure/msal-browser';

@Injectable()
export class CalGuard implements CanActivate {

    constructor(
        private authService: CalService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        if (UrlString.hashContainsKnownProperties(window.location.hash) && BrowserUtils.isInIframe()) {
            console.warn('redirectUri is set to a page with CAL Guard. It is recommended to not set redirectUri to a page that requires authentication.');
            return false;
        }

        this.authService.isUserSignedIn().then(signedIn => {
            if (!signedIn) {
                this.authService.userInitiatedSignIn().then(claims => {
                    if (claims) {
                        return true;
                    }
                    return false;
                });
            }
            return true;
        });
        
        return this.authService.isUserSignedIn();
    }

}