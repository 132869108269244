import { Component, Input, OnInit } from '@angular/core';
import { PagedList } from 'src/app/shared/abstractions/paged-list';
import { Referrer } from 'src/app/shared/abstractions/referrer';
import { GoHttpClient } from 'src/app/shared/services/go-http.service';

@Component({
  selector: 'app-referrers',
  templateUrl: './referrers.component.html',
  styleUrls: ['./referrers.component.scss']
})
export class ReferrersComponent implements OnInit {
  @Input() urlId: number;
  referrerList: PagedList<Referrer>;

  constructor(private goHttpClient: GoHttpClient) { }

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(currentPage = 1) {
    const request = {
      urlMapId: this.urlId,
      currentPage: currentPage
    };

    this.goHttpClient.Get<PagedList<Referrer>>("api/redirect/referrers", request)
      .then(response => {
        this.referrerList = response;
      })
      .catch(error => console.log(error))
  }
}
