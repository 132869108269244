<div class="row" *ngIf="goUrl && urlMap.qrCodeImage">
    <div class="col details-container">
        <div class="row details flex flex-space-around p-4">
            <div class="col-6 flex right flex-col pb-2" *ngIf="canEdit">
                <div *ngIf="isAdmin">
                    <np-alert>
                        Reminder: You have administrative rights, proceed with caution when changing data.
                    </np-alert>
                </div>
                <span class="float-right textRight">
                    <span *ngIf="!isEdit" class="actionButton">
                        <a routerLink="/details/{{ shortName }}/edit" class="btn" [class.disabled]="deleting">
                            <span class="button no-arrow svgTop icon-pencil"></span>
                        </a>
                    </span>
                    <span *ngIf="isEdit" class="actionButton">
                        <a routerLink="/details/{{ shortName }}" class="btn" [class.disabled]="deleting">
                            <span class="button svgTop icon-view-counts"></span>
                        </a>
                    </span>
                    <span class="actionButton">
                        <a class="btn" [class.disabled]="deleting">
                            <span mat-raised-button color="primary" (click)="confirmDialog()"
                                class="button svgTop icon-trash-can"></span>
                        </a>
                    </span>
                </span>
            </div>
            <div class="col-6 flex center flex-col pb-2" *ngIf="canEdit"></div>
            <div class="col-6 flex center flex-col pb-2">
                <div class="section flex flex-col center">
                    <h2>go link</h2>
                    <div id="goDetails" class="flex flex-col p-2">
                        <div class="p-1">
                            <label class="subtitle-2">go url: </label>
                            <a [href]="goUrl" target="_blank">{{ goUrl }}</a>
                        </div>
                        <div *ngIf="!isEdit" class="p-1">
                            <label class="subtitle-2">destination url:</label>
                            <a [href]="urlMap.destinationUrl" target="_blank">{{ urlMap.destinationUrl | truncate:[40]
                                }}</a>
                        </div>
                        <div *ngIf="!isEdit" class="p-1">
                            <label class="subtitle-2">owners:</label>
                            <app-url-owners [urlOwners]="urlMap.owners"></app-url-owners>
                        </div>
                        <div *ngIf="isEdit" class="p-1">
                            <app-update-url-form [urlMap]="urlMap" [deleting]="deleting">
                            </app-update-url-form>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isEdit" id="copyButtonDiv" class="flex align-items-end">
                    <button [disabled]="deleting" id="copyGoUrl" class="button icon-after-copy" (click)="copyToClipboard(goUrl)">copy go url</button>
                </div>
            </div>
            <div class="col-6 flex flex-col pb-2">
                <div class="section center">
                    <h2>qr code</h2>
                    <p class="caption text-center">provided by <a href="https://qrcode.azure.chevron.com/">chevron qr code
                            generator</a></p>
                    <div class="flex center pt-3">
                        <img [src]="urlMap.qrCodeImage" alt="qr-code" with="175" height="175" />
                    </div>
                    <div class="flex center align-items-end">
                        <a id="qrCodeDownload" [href]="urlMap.qrCodeImage" [download]="shortName">
                            <button [disabled]="isEdit || deleting" class="button icon-after-download">download</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isLoading" class="flex center">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>
<app-redirect-activities [shortName]="shortName" *ngIf="goUrl && urlMap.qrCodeImage"></app-redirect-activities>
<app-referrers *ngIf="goUrl && urlMap.qrCodeImage" [urlId]="urlId"></app-referrers>