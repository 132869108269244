import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagedList } from 'src/app/shared/abstractions/paged-list';
import { UrlMap } from 'src/app/shared/abstractions/url-map';
import { GoHttpClient } from 'src/app/shared/services/go-http.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { SearchService } from 'src/app/shared/services/search.service';
import { Subscription } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { SearchUrlSortingType } from 'src/app/shared/enums/search-url-sorting-type';
import { MatDialog } from '@angular/material/dialog';
import { AddOwnersFormComponent } from 'src/app/shared/forms/add-owners-form/add-owners-form.component';
import { CalService } from 'src/app/shared/cal/cal.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { UrlHealthStatus } from 'src/app/shared/enums/url-health-status';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('selectAllCheckbox') selectAllCheckbox: ElementRef;
  @ViewChildren('itemCheckbox') itemCheckboxes: QueryList<ElementRef>;;

  private getMyLinksCountSubscription: Subscription;

  public goUrl: string;

  constructor(
    private goHttpClient: GoHttpClient,
    private activeRoute: ActivatedRoute,
    private authService: CalService,
    private navigationService: NavigationService,
    private searchService: SearchService,
    private dialog: MatDialog) { }

  public faUserPlus = faUserPlus;

  public isLoading: boolean = true;

  // server responses
  public searchTypes: Map<string, number>;
  public urlMaps: PagedList<UrlMap>;

  // selected values
  public selectedSearchType: string;
  public searchText: string;
  public selectedItems: Set<string>;

  public myLinksCount: number;
  public canAddOwners: boolean = false;
  public showHealth: boolean = false;

  // sorting
  sortingType: SearchUrlSortingType;
  columnsOrderTypeDictionary: Record<string, SearchUrlSortingType> = {
    'shortName': SearchUrlSortingType.ShortName,
    'ownerName': SearchUrlSortingType.OwnerName,
    'destinationUrl': SearchUrlSortingType.DestinationUrl,
    'creationDate': SearchUrlSortingType.CreationDate,
    'modifiedDate': SearchUrlSortingType.ModifiedDate,
    'lastHitDate': SearchUrlSortingType.LastHitDate,
    'hitCounts': SearchUrlSortingType.HitCounts
  }
  ascending: boolean;

  UrlHealthStatus = UrlHealthStatus;

  ngOnInit(): void {
    const urlSegments = this.activeRoute.url["_value"];

    if (urlSegments.length > 1)
      this.selectedSearchType = urlSegments[1].path;

    const queryString = new URLSearchParams(window.location.search);
    this.searchText = queryString.get('searchText');

    this.goHttpClient.Get<Map<string, number>>("api/Search/SearchTypes")
      .then(response => { 
        this.searchTypes = response;

        // mark first element as checked
        if (!this.selectedSearchType) {
          this.selectedSearchType = Object.keys(response)[0];
        }

        // check if user should be able to add owners
        const currentUser = this.authService.cvxClaimsPrincipal;
        this.canAddOwners = this.selectedSearchType == 'MyLinks' || currentUser.roles?.includes("Go.Admin");
        this.showHealth = this.selectedSearchType == 'MyLinks' || currentUser.roles?.includes("Go.Admin");

        this.loadPage();
      })
      .catch(error => console.log(error))
      .finally(() => this.isLoading = false);

    this.searchService.getMyLinksCount()
      .then(count => {
        this.myLinksCount = count;
        this.navigationService.updateMyLinksTitle(this.myLinksCount);
      })
      .catch(error => console.log(error))
      .finally(() => this.isLoading = false);

      this.selectedItems = new Set<string>();
  }

  ngOnDestroy(): void {
    this.getMyLinksCountSubscription?.unsubscribe();
  }

  public sortData(sort: Sort) {
    let isSortingOn = sort.direction;
    this.sortingType = isSortingOn ? this.columnsOrderTypeDictionary[sort.active] : undefined;
    this.ascending = isSortingOn ? sort.direction == "asc" : undefined;

    this.loadPage(1, true);
  }

  public onSearchChange() {
    window.history.replaceState({}, '', `/search/${this.selectedSearchType}`);
    this.loadPage();
  }

  public onChange(currentPage: number = 1) {
    this.loadPage(currentPage);
  }

  public copyLinkToClipboard(shortName) {
    navigator.clipboard.writeText(`${document.location.origin}/${shortName}`);
  }

  public getLink(shortName) {
    return `${document.location.origin}/${shortName}`;
  }

  public renderMyLinksTitle(title: string, myLinksCount: number) {
    return `${title} (${myLinksCount})`;
  }

  public deleteLinks() {
    const itemCount = this.selectedItems.size;
    const message = `Are you sure you want to delete the selected (${itemCount}) link${itemCount === 1 ? '' : 's'}?`;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "320px",
      autoFocus: false,
      data: {
        title: 'confirm delete',
        message: message
      }
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        let queryString = Array.from(this.selectedItems.values())
          .map(value => `shortNames=${value}`)
          .join('&');
    
        this.goHttpClient.Delete(`api/Url/bulk?${queryString}`)
          .then(() => {
            this.loadPage(this.urlMaps.metadata?.currentPage, true);
          })
          .catch(ex => console.log(ex));
      }
    });
  }

  public addOwners() {
    const dialogRef = this.dialog.open(AddOwnersFormComponent, {
      width: '550px',
      data: { shortNames: Array.from(this.selectedItems.values())}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadPage(this.urlMaps.metadata?.currentPage, true);
      }
    });
  }

  public onChangeSelectAllCheckbox(event: Event) {
    const isChecked = (<HTMLInputElement>event.target).checked;
    this.toggleSelectAllCheckbox(isChecked);
  }

  public onShortNameClick(item: any): void {
    item.shortNameClicked = true;
  }

  private toggleSelectAllCheckbox(isChecked: boolean) {
    if (this.urlMaps == null) return;

    // Update selectAllCheckbox state if existing
    if (this.selectAllCheckbox != null) {
      this.selectAllCheckbox.nativeElement.checked = isChecked;
    }
    
    // Update all item checkboxes state
    this.itemCheckboxes.forEach(checkbox => {
      checkbox.nativeElement.checked = isChecked;
    });

    // Add or remove all url short names from the selectedItems map
    this.urlMaps.values.forEach(urlMap => {
      if (isChecked) {
        this.selectedItems.add(urlMap.shortName);
      } else {
        this.selectedItems.delete(urlMap.shortName);
      }
    });
  }

  public onChangeItemCheckbox(event: Event, shortName: string) {
    const isChecked = (<HTMLInputElement>event.target).checked;
    this.toggleItemCheckbox(isChecked, shortName);
  }

  private toggleItemCheckbox(isChecked: boolean, shortName: string) {
    // Add or remove url short name from the selectedItems set
    if (isChecked) {
      this.selectedItems.add(shortName);
    } else {
      this.selectedItems.delete(shortName);
    }

    // If all checkboxes are checked, check the selectAllCheckbox
    this.selectAllCheckbox.nativeElement.checked = isChecked && 
      this.itemCheckboxes.toArray().every(checkbox => checkbox.nativeElement.checked);
  }

  private loadPage(currentPage = 1, reload: boolean = false) {
    if (!reload) {
      this.isLoading = true;
    }

    // Force uncheck all items when reloading list
    this.selectedItems.clear();
    this.toggleSelectAllCheckbox(false);

    const request = {
      currentPage: currentPage
    };

    if (this.searchText?.length > 0) {
      request["criteria"] = this.searchText;
      const url = new URL(window.location.href);
      url.searchParams.set('searchText', this.searchText);
      window.history.replaceState({}, '',  url.toString());
    }

    if (this.selectedSearchType != null) {
      request["type"] = this.selectedSearchType;
    }

    if (this.sortingType != null) {
      request["sortingType"] = SearchUrlSortingType[this.sortingType];
      request["ascending"] = this.ascending;
    }

    this.goHttpClient.Get<PagedList<UrlMap>>("api/Search", request)
      .then(response => {
        this.urlMaps = response;
      })
      .catch(error => console.log(error))
      .finally(() => { if (!reload) { this.isLoading = false }});
  }
}