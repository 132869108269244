import { CommonModule } from "@angular/common";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AppConfig } from "../services/app-config.service";
import { CalService } from "./cal.service";
import { CalConfig } from "./cal-config";
import { CalGuard } from "./cal-guard";
import { RoleGuard } from "./role-guard";

export function initializeApp(appConfig: AppConfig) {
    return async (): Promise<any> => {
      await appConfig.load();
      CalConfig.settings = {
          autoSignIn: false,
          popupForLogin: false,
          instance: "https://login.microsoftonline.com/",
          tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
          clientId: AppConfig.settings.clientId,
          redirectUri: AppConfig.settings.baseUrl + '/',
          oidcScopes: ["openid", "profile", "User.Read","offline_access"],
          graphScopes: ['User.Read', 'User.Read.All'],
          cacheLocation: "localStorage"
      };
    }
  }

@NgModule({
    imports: [ CommonModule ],
    providers: [
        CalGuard,
        RoleGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfig],
            multi: true
        },
        CalService
    ],
  })
  export class CalAngularModule {}